// Migrated
<template lang="pug">
nuxt-link.charter-start-box.d-flex.flex-column.position-relative.bg-white.text-black.shadow-below-blur.hover-scale-img(:to="url")
  .max-h-px-400
    .aspect-ratio__4-4.position-relative
      ResponsiveImage.position-absolute.w-100.h-100.top-0.max-h-px-400(
        :image="image",
        alt="Solresor",
        cover
      )
  .h-px-70.d-flex.align-items-center.px-4.charter-start-box__text-with-price(v-if="price")
    .h5.mb-0 {{ text }}
    .h5.mb-0.ml-auto.text-right
      span.font-weight-normal {{ $t('shortFromPrice') }}
      span.text-nowrap {{ $n(price) }}
  .h-px-70.d-flex.align-items-center.justify-content-center.charter-start-box__text-without-price(v-else)
    .h5.mb-0 {{ text }}

  .position-absolute.top-0.left-0.mt-4.z-1
    slot(name="top-left")
</template>

<script>
export default defineNuxtComponent({
  name: 'Box',

  props: {
    url: {
      type: String,
      required: true
    },

    text: {
      type: String,
      default: null,
    },

    price: {
      type: Number,
      default: null
    },

    image: {
      type: String,
      default: null
    }
  },
})
</script>

<style lang="scss" scoped>
.charter-start-box {
  .charter-start-box__text-with-price {
    .h5 {
      font-size: 1.3rem;
    }
  }

  .charter-start-box__text-without-price {
    .h5 {
      font-size: 1.2rem;
    }
  }
}
</style>
